<template>
  <div class="mobile_form">
    <div class="mobile_filtter">
      <div class="container">
        <div class="d-flex text-white">
          <div class="p-2">
            <a v-b-modal.filterbox v-if="categoryName !== 'Flight_Only'">
              <i class="fas fa-filter"></i>{{ $t('search-result.filter') }}</a>
          </div>
          <div class="p-2">
            <a v-b-modal.filtercont data-target="#filtercont"><i class="fas fa-list"></i>{{ $t('search-result.list-view') }}</a>
          </div>
          <div class="p-2 flex-grow-1">
            <a class="searchbox" v-b-modal.searchmodal><i class="fas fa-search"></i>{{ $t('search-result.search') }}</a>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="search_popuparea">
        <b-modal id="searchmodal" hide-footer ref="searchmodal">
          <template #modal-title>
            <h5 class="modal-title" id="exampleModalLabel">
              <img class="img-fluid" :src="`${imageDomain}/assets/img/logo.png`" alt="logo"  width="100" height="100"/>
            </h5>
          </template>

          <div class="titelbox my-3">{{ $t('search-result.search') }}</div>
          <search-tabs @closeSearchMobile="turnOffModal" type='side'/>
        </b-modal>

        <b-modal id="filtercont" hide-footer>
          <template #modal-title>
            <h5 class="modal-title" id="exampleModalLabel">
              <img class="img-fluid" :src="`${imageDomain}/assets/img/logo.png`" alt="logo"  width="100" height="100"/>
            </h5>
          </template>

          <div class="titelbox my-3">{{ $t('search-result.list-view') }}</div>
          <SortButtons :buttonList="sortButtonList" @updateSort="doSort" />
        </b-modal>

        <b-modal id="filterbox" hide-footer>
          <template #modal-title>
            <h5 class="modal-title" id="exampleModalLabel">
              <img class="img-fluid" :src="`${imageDomain}/assets/img/logo.png`" alt="logo"  width="100" height="100"/>
            </h5>
          </template>

          <template #default="{ hide }">
            <div class="titelbox my-3" @click="hide()">{{ $t('search-result.filter') }}</div>

            <filter-panel
              :options="filterOptions"
              @change="filterByOption"
            />
          </template>
        </b-modal>

        <div
          class="modal"
          id="filterbox"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  <img class="img-fluid" :src="`${imageDomain}/assets/img/logo.png`" alt="logo" />
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="titelbox">{{ $t('search-result.filter') }}</div>
              <div class="modal-body">
                <div class="accordion sidePanel" id="accordionParent">
                  <div class="card">
                    <div class="card-header" id="headingStarRating">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#starRating"
                          aria-expanded="false"
                          aria-controls="starRating"
                        >
                          <!-- collapsed -->
                          {{ $t('search-result.one-trip-found') }}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="starRating"
                      class="collapse show"
                      aria-labelledby="headingStarRating"
                      data-parent=""
                    >
                      <div class="card-body">
                        <ul class="list-group list-group-flush filter-hotel">
                          <li class="list-group-item">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                class="custom-control-input"
                                id="radioDayOne"
                                name="radio-stacked"
                                required
                              />
                              <label
                                class="custom-control-label"
                                for="radioDayOne"
                              >
                                <span>{{ $t('search-result.recommended') }}</span>
                                <span class="text-muted mr-3">$1199</span>
                              </label>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                class="custom-control-input"
                                id="radioDayTwo"
                                name="radio-stacked"
                                required
                              />
                              <label
                                class="custom-control-label"
                                for="radioDayTwo"
                              >
                                <span>{{ $t('search-result.special') }}</span>
                                <span class="text-muted mr-3">$999</span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="search-box-container">
      <TextSearchBox @updateSearch="updateSearch"/>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VBModal, BModal } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'MobileFilter',
  mixins: [imageUrlMixin],
  components: {
    BModal,
    SearchTabs: () => import('@/components/search/searchTabs/SearchTabsTheme0'),
    // SearchTab: () => import('@/components/search/SearchTab'),
    // SearchTabHO: () => import('@/components/search/SearchTabHO'),
    SortButtons: () => import('@/components/searchResult/atoms/SortButtons'),
    FilterPanel: () => import('@/components/searchResult/atoms/FilterPanel'),
    // TextSearchBox: () => import('@/components/searchResult/atoms/TextSearchBox'),
  },
  props: {
    category: Object,
    sortButtonList: Array,
    filterOptions: Object,
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters({
      hotelOnly: 'GET_HOTEL_ONLY',
    }),
    categoryName() {
      const { category } = this;
      if (!category) return '';
      return category.code;
    },
  },
  data() {
    return {
    };
  },
  methods: {
    doSort(arr) {
      this.$emit('updateSort', arr);
    },
    turnOffModal() {
      this.$refs.searchmodal.hide();
    },
    updateSearch(str) {
      this.$emit('updateSort', ['hotelName', str]);
    },
    filterByOption(condition) {
      this.$emit('filterChange', condition);
    },
  },
};
</script>

<style>
.modal {
  direction: rtl;
}
.modal-dialog {
  width: calc(100% - 1rem);
}
.modal-header .close {
  padding: 0;
  margin: 0;
}
#searchmodal.modal .modal-body,
#filtercont.modal .modal-body,
#filterbox.modal .modal-body {
  display: block;
}
</style>

<style scoped>
.modal-title {
  filter: invert(0.8);
  filter: invert(30%) sepia(50%) saturate(6327%) hue-rotate(165deg)
    brightness(61%);
  -webkit-filter: invert(30%) sepia(50%) saturate(6327%) hue-rotate(165deg)
    brightness(91%);
  -moz-filter: invert(30%) sepia(50%) saturate(6327%) hue-rotate(165deg)
    brightness(61%);
  max-width: 130px;
}
.modal-header {
  padding: 14px;
  border-bottom: 1px solid #e9ecef;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin: 0;
}
.modal .titelbox {
  background-color: #1eaae8;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding: 8px;
  box-sizing: border-box;
  line-height: 17px;
}
.search-box-container{
  padding: 10px;
}
@media (max-width: 479px) {
  .mobile_form {
    display: block;
  }
}
</style>
